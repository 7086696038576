import { Helmet } from 'react-helmet-async';
import { SectionContentWrapper, SectionHeaderWrapper } from '../styles/Layout';
import styled from 'styled-components';
import { Button, Option, Select, Sheet, Textarea } from '@mui/joy';
import { Fragment, useState } from 'react';
import { copyTextToClipboard } from '../utils/string';
import { Buffer } from 'buffer';

function ScriptPage() {
  const [text, setText] = useState();
  const [selectedOption, setSelectedOption] = useState();

  const options = [
    {
      name: '펫 AI - 제자리',
      type: 'pet-ai',
      path: `${process.env.PUBLIC_URL}/scripts/제자리.xml`,
      desc: `[문서 -> 마비노기 폴더 -> 동물캐릭터 AI] 폴더에 저장 후 펫 우클릭 [행동규칙 설정]`,
    },
    {
      name: '펫 AI - 주인바라기',
      type: 'pet-ai',
      path: `${process.env.PUBLIC_URL}/scripts/주인바라기.xml`,
      desc: `[문서 -> 마비노기 폴더 -> 동물캐릭터 AI] 폴더에 저장 후 펫 우클릭 [행동규칙 설정]`,
    },
    {
      name: '펫 AI - 폭스헌터',
      type: 'pet-ai',
      path: `${process.env.PUBLIC_URL}/scripts/폭스헌터.xml`,
      desc: `[문서 -> 마비노기 폴더 -> 동물캐릭터 AI] 폴더에 저장 후 펫 우클릭 [행동규칙 설정]`,
    },
    {
      name: '펫 AI - 신속의 날개 수련',
      type: 'pet-ai',
      path: `${process.env.PUBLIC_URL}/scripts/신속의날개.xml`,
      desc: `[문서 -> 마비노기 폴더 -> 동물캐릭터 AI] 폴더에 저장 후 펫 우클릭 [행동규칙 설정]`,
    },
    {
      name: '인터렉션 - 깨우기',
      type: 'interaction',
      path: `${process.env.PUBLIC_URL}/scripts/깨우기.xml`,
      desc: `[문서 -> 마비노기 폴더 -> 인터랙션] 폴더에 저장 후 [게임 내 우측 하단 인터랙션] 메뉴에서 설정. 설정 된 펫 소환 후 '인사' 재스처 사용.`,
    },
    // { name: '무한 신들 악보', type: 'sheet', path: `` },
  ];

  return (
    <ScriptPageWrapper>
      <Helmet>
        <title>마비노기 스크립트 :: mabi.zip</title>
        <meta
          name="keywords"
          content="마비노기 스크립트,마비노기 펫 AI,마비노기 인터렉션"
        />
        <meta name="description" content="마비노기 스크립트 모음" />
      </Helmet>

      <SectionHeaderWrapper>스크립트</SectionHeaderWrapper>
      <SectionContentWrapper>
        <ResultWrapper>
          <Select
            color="neutral"
            placeholder="스크립트 선택"
            onChange={async (e, selectedOption) => {
              setText('');

              const selected = options.filter(
                (option) => option.name === selectedOption
              );

              if (selected.length === 1) {
                setSelectedOption(selected[0]);
                try {
                  const response = await fetch(selected[0].path);
                  const text = Buffer.from(
                    await response.arrayBuffer()
                  ).toString('utf16le');
                  setText(text);
                } catch (e) {
                  alert('스크립트를 읽어오는데 실패했습니다.');
                }
              }
            }}
          >
            {options.map((option, index) => {
              return (
                <Fragment key={index}>
                  <Option name={option.name} value={option.name}>
                    {option.name}
                  </Option>
                </Fragment>
              );
            })}
          </Select>

          {text && selectedOption ? (
            <>
              <Textarea
                variant="outlined"
                color="neutral"
                minRows={15}
                maxRows={15}
                readOnly={true}
                value={text}
                sx={{
                  marginTop: '10px',
                }}
              />

              <ButtonsWrapper>
                <Button
                  color="primary"
                  onClick={() => {
                    copyTextToClipboard(text);
                  }}
                >
                  복사
                </Button>

                <a
                  href={selectedOption.path}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                >
                  <Button color="primary">다운로드</Button>
                </a>
              </ButtonsWrapper>
              {selectedOption.desc ? (
                <DescWrapper>
                  <Sheet
                    variant="outlined"
                    sx={{ padding: '10px', fontSize: '13px', color: '#444' }}
                  >
                    {selectedOption.desc}
                  </Sheet>
                </DescWrapper>
              ) : null}
            </>
          ) : null}
        </ResultWrapper>
      </SectionContentWrapper>
    </ScriptPageWrapper>
  );
}

export default ScriptPage;

const ScriptPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ResultWrapper = styled.div`
  margin: 20px auto;
  width: 100%;
  min-width: 250px;
  max-width: 500px;
`;

const ButtonsWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  *:nth-child(n + 2) {
    margin-left: 10px;
  }
`;

const DescWrapper = styled.div`
  margin-top: 25px;
`;
