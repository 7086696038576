import styled from 'styled-components';

function CompactFormattedPrice({ price }) {
  const compactNumberFormatter = new Intl.NumberFormat('ko', {
    notation: 'compact',
    useGrouping: false,
    maximumFractionDigits: 12,
  });

  const compactNumber = (number) => {
    return compactNumberFormatter.format(number);
  };

  const priceString = typeof price === 'string' ? price : price.toString();

  return (
    <CompactFormattedPriceWrapper>
      {compactNumber(priceString.replace(/,/g, ''))}
      <span style={{ marginLeft: '2px', color: 'rgb(255 184 0)' }}>골드</span>
    </CompactFormattedPriceWrapper>
  );
}

export default CompactFormattedPrice;

const CompactFormattedPriceWrapper = styled.span`
  color: #666;
  font-weight: 500;
  text-align: right;
  font-size: 14px;
`;
