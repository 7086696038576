import styled from 'styled-components';

export const MOBILE_WIDTH_CRITERIA = 800;

export const ContentWrapper = styled.main`
  padding-top: 70px;
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1280px;
  min-height: 100%;
  height: fit-content;
  margin: 0 auto 0 auto;
  background-color: white;
  box-sizing: border-box;

  @media screen and (max-width: ${MOBILE_WIDTH_CRITERIA}px) {
    max-width: 480px;
  }
`;

export const SectionHeaderWrapper = styled.h2`
  font-size: 24px;
  margin: 12px 0 22px 12px;
`;

export const SectionContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;

  @media screen and (max-width: ${MOBILE_WIDTH_CRITERIA}px) {
    padding: 0 8px;
    box-sizing: border-box;
  }
`;
