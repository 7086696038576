import styled from 'styled-components';
import { SectionContentWrapper, SectionHeaderWrapper } from '../styles/Layout';
import { Helmet } from 'react-helmet-async';
import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
  Button,
  Divider,
  Input,
  Table,
} from '@mui/joy';
import { useEffect, useState } from 'react';
import { copyTextToClipboard } from '../utils/string';

function CrombasCalc() {
  const [result, setResult] = useState();
  const [input, setInput] = useState('');
  const operations = ['+', '-', '*'];
  const keywords = ['바스', '삼하인', '벨테인', '임볼릭', '루나사'];

  const calculate = (n1, n2, op) => {
    switch (op) {
      case '+':
        return n1 + n2;
      case '*':
        return n1 * n2;
      case '-':
        return n1 - n2;
      default:
        return null;
    }
  };

  useEffect(
    () => {
      setResult(null);

      const parts = input.trim().split(' ');
      if (parts.length === 5 || parts.length === 6) {
        const number1 = parseInt(parts[0]);
        const number2 = parseInt(parts[1]);
        const number3 = parseInt(parts[2]);
        const op1 = parts[3];
        const op2 = parts[4];

        if (
          !isNaN(number1) &&
          !isNaN(number2) &&
          !isNaN(number3) &&
          operations.includes(op1) &&
          operations.includes(op2)
        ) {
          let result = calculate(
            calculate(number1, number2, op1),
            number3,
            op2
          );

          if (parts.length === 6) {
            if (keywords.includes(parts[5])) {
              setResult({
                value: result.toString() + parts[5],
                table: [result.toString() + parts[5]],
              });
            }
          } else {
            setResult({
              value: result,
              table: keywords.map((keyword) => result.toString() + keyword),
            });
          }
        }
      }
    },
    // eslint-disable-next-line
    [input]
  );

  return (
    <CrombasCalcPageWrapper>
      <Helmet>
        <title>마비노기 크롬바스 암호 계산기 :: mabi.zip</title>
        <meta
          name="keywords"
          content="마비노기 크롬바스 암호,마비노기,마비노기 크롬바스 암호 계산기"
        />
        <meta name="description" content="마비노기 채널 점지" />
      </Helmet>

      <SectionHeaderWrapper>크롬바스 암호 계산기</SectionHeaderWrapper>
      <SectionContentWrapper>
        <FormWrapper>
          <FormLabel>쪽지 정보 (스페이스로 구분)</FormLabel>
          <Input
            size="sm"
            type="text"
            placeholder="ex) 1 2 3 + +"
            onChange={(e) => {
              setInput(e.target.value);
            }}
          />
        </FormWrapper>

        {result ? (
          <ResultWrapper>
            <Divider sx={{ marginBottom: '20px' }} />

            <AccordionGroup sx={{ marginTop: '10px' }}>
              <Accordion>
                <AccordionSummary>
                  <div
                    style={{
                      width: '100%',
                      textAlign: 'center',
                    }}
                  >
                    {result.value}
                    <Button
                      size="xs"
                      sx={{ marginLeft: '5px' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        copyTextToClipboard(result.value);
                      }}
                    >
                      복사
                    </Button>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Table variant="outlined" hoverRow={true}>
                    <thead>
                      <tr>
                        <th style={{ textAlign: 'center' }}>클릭시 복사</th>
                      </tr>
                    </thead>
                    <tbody>
                      {result.table.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td
                              style={{ textAlign: 'center', cursor: 'pointer' }}
                              onClick={(e) => {
                                e.stopPropagation();
                                copyTextToClipboard(data);
                              }}
                            >
                              {data}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </AccordionDetails>
              </Accordion>
            </AccordionGroup>
          </ResultWrapper>
        ) : null}
      </SectionContentWrapper>
    </CrombasCalcPageWrapper>
  );
}

export default CrombasCalc;

const CrombasCalcPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-width: 250px;
  max-width: 300px;
  margin: auto;
`;

const FormLabel = styled.label`
  font-weight: 600;
  font-size: 14px;
`;

const ResultWrapper = styled.div`
  margin: 20px auto;
  width: 100%;
  min-width: 250px;
  max-width: 400px;
`;
