import React from 'react';
import styled from 'styled-components';
import './App.css';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuctionCouponPage from './pages/AuctionCouponPage';
import ShiningCrystalPage from './pages/ShiningCrystalPage';
import Header from './components/Header';
import { ContentWrapper } from './styles/Layout';
import SelectChannel from './pages/SelectChannel';
import CrombasCalc from './pages/CrombasCalc';
import ScriptPage from './pages/ScriptPage';

function App() {
  return (
    <>
      <Header />
      <ContentWrapper>
        <AppWrapper>
          <Routes>
            <Route index element={<Navigate to="/auction-coupon" replace />} />
            <Route path="auction-coupon" element={<AuctionCouponPage />} />
            <Route path="shining-crystal" element={<ShiningCrystalPage />} />
            <Route path="select-channel" element={<SelectChannel />} />
            <Route path="crombas-calc" element={<CrombasCalc />} />
            <Route path="script" element={<ScriptPage />} />
          </Routes>
        </AppWrapper>
      </ContentWrapper>
    </>
  );
}

export default App;

const AppWrapper = styled.div`
  width: 100%;
  height: 100%;
`;
