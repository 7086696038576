import { Input } from '@mui/joy';
import styled from 'styled-components';
import CompactFormattedPrice from './CompactFormattedPrice';

function PriceInput({ price, setPrice }) {
  const numberFormatter = new Intl.NumberFormat('ko');

  return (
    <PriceInputWrapper>
      <Input
        fullWidth={true}
        size="sm"
        type="text"
        value={price}
        onChange={(e) => {
          const newValue = e.target.value.replace(/,/g, '');
          if (!isNaN(newValue)) {
            setPrice(numberFormatter.format(newValue));
          }
        }}
        sx={{ '& input': { textAlign: 'right' } }}
      />
      <PriceInputDesc>
        {price !== '0' ? <CompactFormattedPrice price={price} /> : null}
      </PriceInputDesc>
    </PriceInputWrapper>
  );
}

export default PriceInput;

const PriceInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const PriceInputDesc = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  height: 17px;
`;
