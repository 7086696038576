import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
  Button,
  Divider,
  Option,
  Select,
  Table,
} from '@mui/joy';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import PriceInput from '../components/PriceInput';
import CompactFormattedPrice from '../components/CompactFormattedPrice';
import { formattedPriceToPrice } from '../utils/number';
import { Helmet } from 'react-helmet-async';
import { SectionContentWrapper, SectionHeaderWrapper } from '../styles/Layout';

function AuctionCouponPage() {
  const [feeRate, setFeeRate] = useState('4%');
  const [sellingPrice, setSellingPrice] = useState('0');
  const [couponPrice10, setCouponPrice10] = useState('0');
  const [couponPrice20, setCouponPrice20] = useState('0');
  const [couponPrice30, setCouponPrice30] = useState('0');
  const [couponPrice50, setCouponPrice50] = useState('0');
  const [couponPrice100, setCouponPrice100] = useState('0');
  const [calculable, setCalculable] = useState(false);
  const [result, setResult] = useState();

  useEffect(() => {
    setResult(null);

    if (
      feeRate &&
      sellingPrice !== '0' &&
      (couponPrice10 !== '0' ||
        couponPrice20 !== '0' ||
        couponPrice30 !== '0' ||
        couponPrice50 !== '0' ||
        couponPrice100 !== '0')
    ) {
      setCalculable(true);
    } else {
      setCalculable(false);
    }
  }, [
    feeRate,
    sellingPrice,
    couponPrice10,
    couponPrice20,
    couponPrice30,
    couponPrice50,
    couponPrice100,
  ]);

  const calculate = () => {
    const fee = Math.floor(
      (formattedPriceToPrice(sellingPrice) *
        parseInt(feeRate.replace('%', ''))) /
        100.0
    );
    const table = [
      { label: '쿠폰 안씀', rate: 0.0, price: 0 },
      {
        label: '수수료 10% 할인 쿠폰',
        rate: 0.1,
        price: formattedPriceToPrice(couponPrice10),
      },
      {
        label: '수수료 20% 할인 쿠폰',
        rate: 0.2,
        price: formattedPriceToPrice(couponPrice20),
      },
      {
        label: '수수료 30% 할인 쿠폰',
        rate: 0.3,
        price: formattedPriceToPrice(couponPrice30),
      },
      {
        label: '수수료 50% 할인 쿠폰',
        rate: 0.5,
        price: formattedPriceToPrice(couponPrice50),
      },
      {
        label: '수수료 100% 할인 쿠폰',
        rate: 1.0,
        price: formattedPriceToPrice(couponPrice100),
      },
    ];

    let bestBenefit = -fee;
    let best = 0;
    for (let i = 0; i < table.length; ++i) {
      if (table[i].rate === 0.0 || table[i].price > 0) {
        table[i].benefit = Math.floor(fee * table[i].rate) - table[i].price;
        if (table[i].benefit > bestBenefit) {
          best = i;
          bestBenefit = table[i].benefit;
        }
      }
    }

    setResult({
      fee,
      table,
      best,
    });
  };

  return (
    <AuctionCouponPageWrapper>
      <Helmet>
        <title>마비노기 경매장 수수료 쿠폰 계산기 :: mabi.zip</title>
        <meta
          name="keywords"
          content="마비노기 경매장,마비노기,마비노기 쿠폰,마비노기 경매장 쿠폰,마비노기 경매장 수수료,마비노기 수수료,마비노기 경매장 수수료 쿠폰,마비노기 경매장 수수료 쿠폰 계산기,마비노기 수수료 쿠폰 계산기,마비노기 경매장 수수료 계산기,마비노기 경매장 수수료 할인 쿠폰,마비노기 경매장 수수료 할인 쿠폰 계산기"
        />
        <meta name="description" content="마비노기 경매장 수수료 쿠폰 계산기" />
      </Helmet>

      <SectionHeaderWrapper>경매장 수수료 쿠폰 계산기</SectionHeaderWrapper>
      <SectionContentWrapper>
        <FormWrapper>
          <SellingInfoWrapper>
            <FeeRateWrapper>
              <FormLabel htmlFor="feeRate" id="feeRateLabel">
                경매장 수수료율
              </FormLabel>
              <Select
                size="sm"
                value={feeRate}
                onChange={(e, newValue) => {
                  setFeeRate(newValue);
                }}
                slotProps={{
                  button: {
                    id: 'feeRate',
                    'aria-labelledby': 'feeRateLabel feeRate',
                  },
                }}
              >
                <Option value="5%">기본 (아이템 판매가의 5%)</Option>
                <Option value="4%">멤버십 (아이템 판매가의 4%)</Option>
              </Select>
            </FeeRateWrapper>
            <SellingPriceWrapper>
              <FormLabel>판매가</FormLabel>
              <PriceInput price={sellingPrice} setPrice={setSellingPrice} />
            </SellingPriceWrapper>
          </SellingInfoWrapper>
          <Divider orientation="vertical" sx={{ margin: '0 20px' }} />
          <CouponPriceListWrapper>
            <CouponPriceListItemWrapper>
              <FormLabel>10% 할인 쿠폰 가격</FormLabel>
              <PriceInput price={couponPrice10} setPrice={setCouponPrice10} />
            </CouponPriceListItemWrapper>
            <CouponPriceListItemWrapper>
              <FormLabel>20% 할인 쿠폰 가격</FormLabel>
              <PriceInput price={couponPrice20} setPrice={setCouponPrice20} />
            </CouponPriceListItemWrapper>
            <CouponPriceListItemWrapper>
              <FormLabel>30% 할인 쿠폰 가격</FormLabel>
              <PriceInput price={couponPrice30} setPrice={setCouponPrice30} />
            </CouponPriceListItemWrapper>
            <CouponPriceListItemWrapper>
              <FormLabel>50% 할인 쿠폰 가격</FormLabel>
              <PriceInput price={couponPrice50} setPrice={setCouponPrice50} />
            </CouponPriceListItemWrapper>
            <CouponPriceListItemWrapper>
              <FormLabel>100% 할인 쿠폰 가격</FormLabel>
              <PriceInput price={couponPrice100} setPrice={setCouponPrice100} />
            </CouponPriceListItemWrapper>
          </CouponPriceListWrapper>
        </FormWrapper>
        <ButtonWrapper>
          <Button color="primary" disabled={!calculable} onClick={calculate}>
            계산하기
          </Button>
        </ButtonWrapper>
        {result ? (
          <ResultWrapper>
            <Divider sx={{ marginBottom: '20px' }} />
            <div style={{ textAlign: 'right', fontSize: '14px' }}>
              경매장 수수료: <CompactFormattedPrice price={result.fee} />
            </div>

            <AccordionGroup sx={{ marginTop: '10px' }}>
              <Accordion>
                <AccordionSummary>
                  <div
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      fontWeight: '700',
                    }}
                  >
                    {result.table[result.best].label}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Table variant="plain">
                    <thead>
                      <tr>
                        <th style={{ textAlign: 'center' }}>쿠폰</th>
                        <th style={{ textAlign: 'center' }}>이득</th>
                      </tr>
                    </thead>
                    <tbody>
                      {result.table.map((data, index) => {
                        if (typeof data.benefit !== 'number') {
                          return null;
                        }

                        return (
                          <tr key={index}>
                            <td style={{ textAlign: 'center' }}>
                              {data.label}
                            </td>
                            <td style={{ textAlign: 'right' }}>
                              <CompactFormattedPrice price={data.benefit} />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td
                          style={{
                            textAlign: 'center',
                            fontWeight: 700,
                            fontSize: 16,
                          }}
                        >
                          최대 이득 쿠폰
                        </td>
                        <td
                          style={{
                            textAlign: 'center',
                            fontWeight: 700,
                            fontSize: 16,
                          }}
                        >
                          {result.table[result.best].label}
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                </AccordionDetails>
              </Accordion>
            </AccordionGroup>
          </ResultWrapper>
        ) : null}
      </SectionContentWrapper>
    </AuctionCouponPageWrapper>
  );
}

export default AuctionCouponPage;

const AuctionCouponPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;

  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const SellingInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-width: 150px;
  max-width: 250px;
`;

const FeeRateWrapper = styled.div`
  width: 100%;
`;

const FormLabel = styled.label`
  font-weight: 600;
  font-size: 14px;
`;

const SellingPriceWrapper = styled.div`
  width: 100%;
  margin-top: 15px;
`;

const CouponPriceListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 150px;
  max-width: 250px;
  & > *:nth-child(n + 2) {
    margin-top: 8px;
  }
`;

const CouponPriceListItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ResultWrapper = styled.div`
  margin: 20px auto;
  min-width: 360px;
  max-width: 500px;
`;
