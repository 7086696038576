import { Fragment, useCallback, useEffect, useState } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { MOBILE_WIDTH_CRITERIA } from '../styles/Layout';
import { Dropdown, IconButton, Menu, MenuButton } from '@mui/joy';
import { ExpandIcon } from '../styles/Icon';

function Header() {
  const navigate = useNavigate();
  const match = useMatch('/:menu');
  const [currentMenu, setCurrentMenu] = useState('/');
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuOpenChange = useCallback((e, isOpen) => {
    setMenuOpen(isOpen);
  }, []);

  useEffect(() => {
    if (match) {
      setCurrentMenu(match.params?.menu);
      setMenuOpen(false);
    }
  }, [match]);

  const handleResize = () => {
    if (window.innerWidth > MOBILE_WIDTH_CRITERIA) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const renderMenuItems = () => {
    const menuInfo = [
      { name: '경매장 수수료 쿠폰', path: 'auction-coupon' },
      { name: '빛나는 수정 조각', path: 'shining-crystal' },
      { name: '채널 점지', path: 'select-channel' },
      { name: '크롬바스 계산기', path: 'crombas-calc' },
      { name: '스크립트', path: 'script' },
    ];

    return menuInfo.map((item, index) => (
      <Fragment key={index}>
        <MenuItem
          $active={currentMenu === item.path}
          onClick={() => navigate(`/${item.path}`)}
        >
          {item.name}
        </MenuItem>
      </Fragment>
    ));
  };

  return (
    <HeaderWrapper>
      <HeaderContentWrapper>
        <IconWrapper
          style={{ cursor: 'pointer' }}
          onClick={() => {
            navigate('/');
          }}
        >
          <IconImg />
          <HeaderText>mabi.zip</HeaderText>
        </IconWrapper>
        <MenuWrapper>{renderMenuItems()}</MenuWrapper>
        <MobileMenuWrapper>
          <Dropdown open={menuOpen} onOpenChange={handleMenuOpenChange}>
            <MenuButton
              slots={{ root: IconButton }}
              slotProps={{ root: { variant: 'outlined', color: 'neutral' } }}
            >
              <ExpandIcon fill={'#666'} />
            </MenuButton>
            <Menu placement="bottom-end">{renderMenuItems()}</Menu>
          </Dropdown>
        </MobileMenuWrapper>
      </HeaderContentWrapper>
    </HeaderWrapper>
  );
}

export default Header;

const HeaderWrapper = styled.header`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 70px;
  background-color: rgb(251, 252, 254);
  display: flex;
  align-items: center;
  border-bottom: solid 1px rgb(238, 238, 238);
  z-index: 1;
`;

const HeaderContentWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  height: 70px;
  margin: auto;
  padding: 0 20px;

  @media screen and (max-width: ${MOBILE_WIDTH_CRITERIA}px) {
    padding: 0 8px;
    max-width: 480px;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
`;

const IconImg = styled.img.attrs(() => ({
  src: `${process.env.PUBLIC_URL}/icon.png`,
  alt: 'icon-image',
}))`
  width: auto;
  height: 20px;
`;

const HeaderText = styled.div`
  margin-left: 5px;
  font-size: 18px;
  font-weight: 500;
  color: #666;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 100px;

  @media screen and (max-width: ${MOBILE_WIDTH_CRITERIA}px) {
    display: none;
  }
`;

const MobileMenuWrapper = styled.div`
  display: none;

  @media screen and (max-width: ${MOBILE_WIDTH_CRITERIA}px) {
    display: flex;
    margin-left: auto;
  }
`;

const MenuItem = styled.h1`
  height: 70px;
  padding: 0 20px;
  display: flex;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-bottom: 1px solid
    ${(p) => (p.$active ? 'rgb(255 184 0)' : 'transparent')};
  cursor: pointer;
  font-weight: 500;

  &: hover {
    font-weight: 700;
  }

  @media screen and (max-width: ${MOBILE_WIDTH_CRITERIA}px) {
    padding: 8px 30px;
    margin: 0;
    height: auto;
    border: none;
    background-color: ${(p) => (p.$active ? '#eee' : 'transparent')};

    &: nth-child(n + 2) {
      border-top: 1px solid #eee !important;
    }
  }
`;
