import { Helmet } from 'react-helmet-async';
import { SectionContentWrapper, SectionHeaderWrapper } from '../styles/Layout';
import styled from 'styled-components';
import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
  Button,
  Checkbox,
  Divider,
  Input,
  Table,
} from '@mui/joy';
import { useEffect, useState } from 'react';

function SelectChannel() {
  const [ignoreRaidChannel, setIgnoreRaidChannel] = useState(true);
  const [maxChannel, setMaxChannel] = useState(24);
  const [calculable, setCalculable] = useState(false);
  const [result, setResult] = useState();

  useEffect(() => {
    setResult(null);

    if (!!maxChannel) {
      setCalculable(true);
    } else {
      setCalculable(false);
    }
  }, [maxChannel]);

  const calculate = () => {
    const channels = [...Array(parseInt(maxChannel) + 1).keys()];
    channels.splice(0, 1);

    let ignoreChannels = [11];
    if (ignoreRaidChannel) {
      ignoreChannels = ignoreChannels.concat([12, 13, 14, 15]);
    }

    let selectedChannel;
    ignoreChannels.forEach((v) => {
      const index = channels.indexOf(v);
      if (index !== -1) {
        channels.splice(index, 1);
      }
    });

    if (channels.length > 0) {
      selectedChannel = channels[Math.floor(Math.random() * channels.length)];
    }

    setResult({
      ignoreChannels,
      selectedChannel,
    });
  };

  return (
    <SelectChannelPageWrapper>
      <Helmet>
        <title>마비노기 채널 점지 :: mabi.zip</title>
        <meta
          name="keywords"
          content="마비노기 채널 점지,마비노기,마비노기 채널 고르기,마비노기,마비노기 채널"
        />
        <meta name="description" content="마비노기 채널 점지" />
      </Helmet>

      <SectionHeaderWrapper>채널 점지</SectionHeaderWrapper>
      <SectionContentWrapper>
        <FormWrapper>
          <ChannelInfoWrapper>
            <FormLabel>채널 수</FormLabel>
            <Input
              size="sm"
              type="number"
              slotProps={{ input: { min: 1, max: 100 } }}
              value={maxChannel}
              onChange={(e) => {
                setMaxChannel(Math.max(Math.min(e.target.value, 100), 1));
              }}
            />
          </ChannelInfoWrapper>

          <Checkbox
            size="sm"
            color="primary"
            label={'레이드 채널 제외'}
            defaultChecked={true}
            onChange={(e) => {
              setIgnoreRaidChannel(e.target.checked);
            }}
            sx={{ margin: '15px auto' }}
          />
        </FormWrapper>

        <ButtonWrapper>
          <Button color="primary" disabled={!calculable} onClick={calculate}>
            채널 점지
          </Button>
        </ButtonWrapper>

        {result ? (
          <ResultWrapper>
            <Divider sx={{ marginBottom: '20px' }} />

            <AccordionGroup sx={{ marginTop: '10px' }}>
              <Accordion>
                <AccordionSummary>
                  <div
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      fontWeight: '700',
                    }}
                  >
                    {result.selectedChannel} 채널
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Table variant="plain">
                    <tbody>
                      <tr>
                        <td style={{ textAlign: 'center' }}>제외된 채널</td>
                        <td style={{ textAlign: 'center' }}>
                          {result.ignoreChannels.join(',')}
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td
                          style={{
                            textAlign: 'center',
                            fontWeight: 700,
                            fontSize: 16,
                          }}
                        >
                          점지된 채널
                        </td>
                        <td
                          style={{
                            textAlign: 'center',
                            fontWeight: 700,
                            fontSize: 16,
                          }}
                        >
                          {result.selectedChannel} 채널
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                </AccordionDetails>
              </Accordion>
            </AccordionGroup>
          </ResultWrapper>
        ) : null}
      </SectionContentWrapper>
    </SelectChannelPageWrapper>
  );
}

export default SelectChannel;

const SelectChannelPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ChannelInfoWrapper = styled.div`
  width: 130px;
  display: flex;
  flex-direction: column;
  margin: auto;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-width: 250px;
  max-width: 400px;
  margin: auto;
`;

const FormLabel = styled.label`
  font-weight: 600;
  font-size: 14px;
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;

  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const ResultWrapper = styled.div`
  margin: 20px auto;
  width: 100%;
  min-width: 250px;
  max-width: 500px;
`;
