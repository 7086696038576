import styled from 'styled-components';
import PriceInput from '../components/PriceInput';
import { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
  Button,
  Divider,
  Table,
} from '@mui/joy';
import { formattedPriceToPrice } from '../utils/number';
import CompactFormattedPrice from '../components/CompactFormattedPrice';
import { Helmet } from 'react-helmet-async';
import { SectionContentWrapper, SectionHeaderWrapper } from '../styles/Layout';

function ShiningCrystalPage() {
  const [ruinsPrice, setRuinsPrice] = useState('0');
  const [dreamPiecePrice, setDreamPiecePrice] = useState('0');
  const [forestPrice, setForestPrice] = useState('0');
  const [calculable, setCalculable] = useState(false);
  const [result, setResult] = useState();

  useEffect(() => {
    setResult(null);

    let count = 0;
    if (ruinsPrice !== '0') {
      ++count;
    }
    if (dreamPiecePrice !== '0') {
      ++count;
    }
    if (forestPrice !== '0') {
      ++count;
    }

    if (count >= 1) {
      setCalculable(true);
    } else {
      setCalculable(false);
    }
  }, [ruinsPrice, dreamPiecePrice, forestPrice]);

  const calculate = () => {
    const caseResult = {
      ruinsCase: formattedPriceToPrice(ruinsPrice) / 35.0,
      forestCase: formattedPriceToPrice(forestPrice) / 150.0,
      dreamPieceCase: formattedPriceToPrice(dreamPiecePrice) / 200.0,
    };

    const table = [
      {
        label: '결정화된 겨울의 잔해',
        price: caseResult.ruinsCase,
      },
      {
        label: '잘려 나간 겨울의 꿈 결정',
        price: caseResult.dreamPieceCase,
      },
      {
        label: '포레스트 레인저',
        price: caseResult.forestCase,
      },
    ];

    let bestBenefit = 0;
    let best = 0;
    for (let i = 0; i < table.length; ++i) {
      if (table[i].price > 0) {
        if (table[i].price > bestBenefit) {
          best = i;
          bestBenefit = table[i].price;
        }
      }
    }

    setResult({
      caseResult,
      table,
      best,
    });
  };

  return (
    <ShiningCrystalPageWrapper>
      <Helmet>
        <title>마비노기 빛나는 수정 조각 계산기 :: mabi.zip</title>
        <meta
          name="description"
          content="마비노기 글렌베르나 빛나는 수정 계산기"
        />
        <meta
          name="keywords"
          content="마비노기 글렌베르나 빛나는 수정 계산기,마비노기 글렌베르나 빛나는 수정 계산기,마비노기 빛나는 수정 계산기,마비노기 수정 계산기"
        />
      </Helmet>

      <SectionHeaderWrapper>빛나는 수정 조각 계산기</SectionHeaderWrapper>
      <SectionContentWrapper>
        <FormWrapper>
          <FormLabel>결정화된 겨울의 잔해 가격</FormLabel>
          <PriceInput price={ruinsPrice} setPrice={setRuinsPrice} />

          <FormLabel>잘려 나간 겨울의 꿈 결정 가격</FormLabel>
          <PriceInput price={dreamPiecePrice} setPrice={setDreamPiecePrice} />

          <FormLabel>포레스트 레인저 가격</FormLabel>
          <PriceInput price={forestPrice} setPrice={setForestPrice} />
        </FormWrapper>

        <ButtonWrapper>
          <Button color="primary" disabled={!calculable} onClick={calculate}>
            계산하기
          </Button>
        </ButtonWrapper>
        {result ? (
          <ResultWrapper>
            <Divider sx={{ marginBottom: '20px' }} />

            <AccordionGroup sx={{ marginTop: '10px' }}>
              <Accordion>
                <AccordionSummary>
                  <div
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      fontWeight: '700',
                    }}
                  >
                    {result.table[result.best].label}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Table variant="plain">
                    <thead>
                      <tr>
                        <th style={{ textAlign: 'center' }}>아이템</th>
                        <th style={{ textAlign: 'center' }}>가격</th>
                      </tr>
                    </thead>
                    <tbody>
                      {result.table.map((data, index) => {
                        if (data.price === 0) {
                          return null;
                        }

                        return (
                          <tr key={index}>
                            <td style={{ textAlign: 'center' }}>
                              {data.label} - 수정 1개 가치
                            </td>
                            <td style={{ textAlign: 'right' }}>
                              <CompactFormattedPrice price={data.price} />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td
                          style={{
                            textAlign: 'center',
                            fontWeight: 700,
                            fontSize: 16,
                          }}
                        >
                          최대 이득
                        </td>
                        <td
                          style={{
                            textAlign: 'center',
                            fontWeight: 700,
                            fontSize: 16,
                          }}
                        >
                          {result.table[result.best].label}
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                </AccordionDetails>
              </Accordion>
            </AccordionGroup>
          </ResultWrapper>
        ) : null}
      </SectionContentWrapper>
    </ShiningCrystalPageWrapper>
  );
}

export default ShiningCrystalPage;

const ShiningCrystalPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-width: 250px;
  max-width: 400px;
  margin: auto;
`;

const FormLabel = styled.label`
  font-weight: 600;
  font-size: 14px;
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;

  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const ResultWrapper = styled.div`
  margin: 20px auto;
  width: 100%;
  min-width: 250px;
  max-width: 600px;
`;
